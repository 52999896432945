import { getSelectedLocale } from 'utils/getLocaleId'

export const formatWebsite = value => {
  if (!value || typeof value !== 'string') return value
  return value.startsWith('http') ? value : `https://${value}`
}

type FormatPriceOptions = {
  defaultValue?: string
  fractionDigits?: number
}

// https://wwl.atlassian.net/wiki/spaces/WWLFW/pages/2243690497/NFR-1
export const formatPrice = (n, options: FormatPriceOptions = {}) => {
  const { defaultValue = '-', fractionDigits } = options

  if (!n) return defaultValue
  if (typeof n !== 'number') return n

  const userLocale = getSelectedLocale()

  const formatOptions: Intl.NumberFormatOptions = { maximumFractionDigits: 2 }
  if (fractionDigits) {
    formatOptions.minimumFractionDigits = fractionDigits
    formatOptions.maximumFractionDigits = fractionDigits
  }

  const formattedPrice = n.toLocaleString('ru', formatOptions)

  return userLocale === 'en' ? formattedPrice.replace(',', '.') : formattedPrice
}

export const formatNumber = (
  value: number,
  options: Intl.NumberFormatOptions = {}
) => {
  return new Intl.NumberFormat(getSelectedLocale(), {
    style: 'decimal',
    maximumFractionDigits: 2,
    ...options,
  }).format(value)
}

export const numberWithSpaces = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const formatBytes = (sizeInBytes, decimals = 2) => {
  if (!+sizeInBytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(sizeInBytes) / Math.log(k))

  return `${parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const formatIngredient = item => {
  const code = `${item?.code ? ` [${item.code}]` : ''}`
  const ingredient = `${item?.localizedName ?? ''}`
  return item?.category?.isPrintable
    ? `${item?.category?.localizedName} (${ingredient.toLowerCase()})${code}`
    : `${ingredient}${code}`
}

export const toFixedFractionDigits = (
  num: number,
  fractionDigits: number = 2
): number => {
  const n = Math.pow(10, fractionDigits)
  return Math.round(num * n) / n
}
