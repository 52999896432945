import { omit } from 'ramda'
import { createSlice } from '@reduxjs/toolkit'

import {
  OFFERS_ROUTE_NAMES,
  DATABASE_ROUTE_NAMES,
  MY_REVIEW_ROUTE_NAME,
  ADMIN_ROUTE_NAMES,
  E_LABELS_ROUTE_NAME,
  RETAIL_ROUTE_NAMES,
} from 'utils/consts'
import { MY_WINES_ROUTE_NAME } from 'utils/consts/myWinesFilters'

const defaultFilterState = {
  filtersApplied: false,
  preset: null,
  appliedPreset: null,
  filters: null,
  lastUpdatedFilter: '',
  alcoholLevels: null,
  currencyDefault: '',
  priceInterval: null,
  filterErrors: [],
}

const initialState = {
  accBuyWineAnalysis: {
    ...defaultFilterState,
  },
  accBuyWineOffers: {
    ...defaultFilterState,
  },
  accBuyWineAnalysisRating: {
    ...defaultFilterState,
  },
  accBuyWineWines: {
    ...defaultFilterState,
  },
  accSellWineMyOffers: {
    ...defaultFilterState,
  },
  accRetailEShops: {
    ...defaultFilterState,
  },
  accRetailWines: {
    ...defaultFilterState,
  },
  accRetailBuyersWine: {
    ...defaultFilterState,
  },
  accRetailGeoIndications: {
    ...defaultFilterState,
  },
  accDatabaseProducers: {
    ...defaultFilterState,
  },
  accDatabaseProducersDuplicates: {
    ...defaultFilterState,
  },
  accDatabaseBrands: {
    ...defaultFilterState,
  },
  accDatabaseWines: {
    ...defaultFilterState,
  },
  accDatabaseGeoIndication: {
    ...defaultFilterState,
  },
  accDatabaseExhibitions: {
    ...defaultFilterState,
  },
  accReviewsMyReviews: {
    ...defaultFilterState,
  },
  accAdministrationMerchants: {
    ...defaultFilterState,
  },
  accAdministrationReviews: {
    ...defaultFilterState,
  },
  accAdministrationOffers: {
    ...defaultFilterState,
  },
  accAdministrationRequests: {
    ...defaultFilterState,
  },
  accAdministrationUsers: {
    ...defaultFilterState,
  },
  accAdministrationRequestsPotentialProducers: {
    ...defaultFilterState,
  },
  accAdministrationCompanies: {
    ...defaultFilterState,
  },
  accAdministrationELabels: {
    ...defaultFilterState,
  },
  accAdministrationRetailOffers: {
    ...defaultFilterState,
  },
  accAdministrationPriceSources: {
    ...defaultFilterState,
  },
  accAdministrationEShops: {
    ...defaultFilterState,
  },
  accMyWinesELabels: {
    ...defaultFilterState,
  },
  accMyWinesWines: {
    ...defaultFilterState,
  },
}
const buyWineOffers = 'setAccBuyWineOffers'
const buyWineOffersByName = 'setAccBuyWineOffersByName'
const buyWineAnalysis = 'setAccBuyWineAnalysis'
const buyWineAnalysisByName = 'setAccBuyWineAnalysisByName'
const buyWineWines = 'setAccBuyWineWines'
const buyWineWinesByName = 'setAccBuyWineWinesByName'
const retailEShops = 'setAccRetailEShops'
const retailEShopsByName = 'setAccRetailEShopsByName'
const retailWines = 'setAccRetailWines'
const retailWinesByName = 'setAccRetailWinesByName'
const retailBuyersWine = 'setAccRetailBuyersWine'
const retailBuyersWineByName = 'setAccRetailBuyersWineByName'
const retailGeoIndications = 'setAccRetailGeoIndications'
const retailGeoIndicationsByName = 'setAccRetailGeoIndicationsByName'
const buyWineAnalysisRating = 'setAccBuyWineAnalysisRating'
const buyWineAnalysisRatingByName = 'setAccBuyWineAnalysisRatingByName'
const sellWineMyOffers = 'setAccSellWineMyOffers'
const databaseProducers = 'setAccDatabaseProducers'
const adminOffers = 'setAccAdministrationOffers'
const adminOffersByName = 'setAccAdministrationOffersByName'
const databaseProducersDuplicates = 'setAccDatabaseProducersDuplicates'
const databaseBrands = 'setAccDatabaseBrands'
const databaseWines = 'setAccDatabaseWines'
const databaseGeoIndication = 'setAccDatabaseGeoIndication'
const databaseExhibitions = 'setAccDatabaseExhibitions'
const myReviews = 'setAccReviewsMyReviews'
const administrationReviews = 'setAccAdministrationReviews'
const administrationRequests = 'setAccAdministrationRequests'
const administrationCompanies = 'setAccAdministrationCompanies'
const administrationELabels = 'setAccAdministrationELabels'
const administrationUsers = 'setAccAdministrationUsers'
const administrationMerchants = 'setAccAdministrationEShops'
const administrationTradeOffers = 'setAccAdministrationRetailOffers'
const administrationPriceSources = 'setAccAdministrationPriceSources'
const potentialProducers = 'setAccAdministrationRequestsPotentialProducers'
const eLabels = 'setAccMyWinesELabels'
const myWines = 'setAccMyWinesWines'

const setFiltersApplied = ({ state, table, payload }) => {
  state[table] = { ...state[table], filtersApplied: payload }
}
const setAlcoholLevels = ({ state, table, payload }) => {
  state[table] = { ...state[table], alcoholLevels: payload }
}
const setCurrencyDefault = ({ state, table, payload }) => {
  state[table] = { ...state[table], currencyDefault: payload }
}
const setPriceInterval = ({ state, table, payload }) => {
  state[table] = {
    ...state[table],
    priceInterval: {
      minValue: payload?.minValue,
      maxValue: Math.ceil(payload?.maxValue),
    },
  }
}
const setPriceIntervalByKey = ({ state, table, payload }) => {
  state[table] = {
    ...state[table],
    [payload.intervalKey]: {
      minValue: payload?.interval?.minValue,
      maxValue: Math.ceil(payload?.interval?.maxValue),
    },
  }
}
const setDrinkingWindowInterval = ({ state, table, payload }) => {
  state[table] = {
    ...state[table],
    drinkingWindow: {
      minValue: payload?.minValue,
      maxValue: Math.ceil(payload?.maxValue),
    },
  }
}
const setRrpInterval = ({ state, table, payload }) => {
  state[table] = {
    ...state[table],
    rrpInterval: {
      minValue: payload?.minValue,
      maxValue: Math.ceil(payload?.maxValue),
    },
  }
}
const setParticipantsInterval = ({ state, table, payload }) => {
  state[table] = {
    ...state[table],
    participantsFilter: {
      minValue: payload?.minValue,
      maxValue: Math.ceil(payload?.maxValue),
    },
  }
}
const setFilterByName = ({ state, table, payload }) => {
  state[table] = {
    ...state[table],
    [payload?.filterName]: payload?.filterData,
  }
}
const setFilters = ({ state, table, payload }) => {
  state[table] = { ...state[table], filters: payload }
}
const removeFilter = ({ state, table, payload }) => {
  state[table] = {
    ...state[table],
    preset: null,
    filters: omit([payload], { ...state[table].filters }) || null,
  }
}
const setFilter = ({ state, table, payload }) => {
  state[table] = {
    ...state[table],
    preset: null,
    filters: {
      ...state[table].filters,
      ...payload,
    },
  }
}
const setLastUpdatedFilter = ({ state, table, payload }) => {
  state[table] = { ...state[table], lastUpdatedFilter: payload }
}
const setAppliedPreset = ({ state, table, payload }) => {
  state[table] = { ...state[table], appliedPreset: payload }
}
const setPreset = ({ state, table, payload }) => {
  state[table] = { ...state[table], preset: payload }
}
const setFilterErrors = ({ state, table, payload }) => {
  let filterErrors = []
  if (payload?.action === 'add') {
    filterErrors = state[table].filterErrors.includes(payload.filterName)
      ? state[table].filterErrors
      : [...state[table].filterErrors, payload.filterName]
  } else if (payload?.action === 'remove') {
    filterErrors = state[table].filterErrors.filter(
      filter => filter !== payload.filterName
    )
  }

  state[table] = { ...state[table], filterErrors }
}

const tableFiltersSlice = createSlice({
  name: 'tableFilters',
  initialState,
  reducers: {
    clear: () => {
      return initialState
    },

    // ------ administration/price-sources ------
    [`${administrationPriceSources}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.adminPriceSources,
        payload,
      })
    },
    [`${administrationPriceSources}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.adminPriceSources,
        payload,
      })
    },
    [`${administrationPriceSources}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminPriceSources,
        payload,
      })
    },
    [`${administrationPriceSources}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminPriceSources,
        payload,
      })
    },
    [`${administrationPriceSources}LastUpdatedFilter`]: (
      state,
      { payload }
    ) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminPriceSources,
        payload,
      })
    },
    [`${administrationPriceSources}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.adminPriceSources,
        payload,
      })
    },
    [`${administrationPriceSources}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminPriceSources,
        payload,
      })
    },
    [`${administrationPriceSources}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminPriceSources,
        payload,
      })
    },
    // ------ administration/trade-offers ------
    [`${administrationTradeOffers}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}ByName`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    [`${administrationTradeOffers}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminTradeOffers,
        payload,
      })
    },
    // ------ administration/merchants ------
    [`${administrationMerchants}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    [`${administrationMerchants}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    [`${administrationMerchants}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    [`${administrationMerchants}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    [`${administrationMerchants}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    [`${administrationMerchants}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    [`${administrationMerchants}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    [`${administrationMerchants}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    [`${administrationMerchants}ByName`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    [`${administrationMerchants}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: ADMIN_ROUTE_NAMES.adminMerchants,
        payload,
      })
    },
    // ------ administration/users ------
    [`${administrationUsers}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.adminUsers,
        payload,
      })
    },
    [`${administrationUsers}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.adminUsers,
        payload,
      })
    },
    [`${administrationUsers}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminUsers,
        payload,
      })
    },
    [`${administrationUsers}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminUsers,
        payload,
      })
    },
    [`${administrationUsers}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminUsers,
        payload,
      })
    },
    [`${administrationUsers}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.adminUsers,
        payload,
      })
    },
    [`${administrationUsers}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminUsers,
        payload,
      })
    },
    [`${administrationUsers}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminUsers,
        payload,
      })
    },
    // ------ administration/reviews ------
    [`${administrationReviews}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    [`${administrationReviews}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    [`${administrationReviews}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    [`${administrationReviews}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    [`${administrationReviews}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    [`${administrationReviews}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    [`${administrationReviews}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    [`${administrationReviews}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    [`${administrationReviews}DrinkingWindow`]: (state, { payload }) => {
      setDrinkingWindowInterval({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    [`${administrationReviews}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: ADMIN_ROUTE_NAMES.adminReviews,
        payload,
      })
    },
    // ------ administration/requests ------
    [`${administrationRequests}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    [`${administrationRequests}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: ADMIN_ROUTE_NAMES.adminRequests,
        payload,
      })
    },
    // ------ administration/requests/potential-producers ------
    [`${potentialProducers}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.potentialProducers,
        payload,
      })
    },
    [`${potentialProducers}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.potentialProducers,
        payload,
      })
    },
    [`${potentialProducers}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.potentialProducers,
        payload,
      })
    },
    [`${potentialProducers}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.potentialProducers,
        payload,
      })
    },
    [`${potentialProducers}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.potentialProducers,
        payload,
      })
    },
    [`${potentialProducers}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.potentialProducers,
        payload,
      })
    },
    [`${potentialProducers}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.potentialProducers,
        payload,
      })
    },
    [`${potentialProducers}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.potentialProducers,
        payload,
      })
    },
    // ------ administration/companies ------
    [`${administrationCompanies}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.adminCompanies,
        payload,
      })
    },
    [`${administrationCompanies}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.adminCompanies,
        payload,
      })
    },
    [`${administrationCompanies}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminCompanies,
        payload,
      })
    },
    [`${administrationCompanies}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminCompanies,
        payload,
      })
    },
    [`${administrationCompanies}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminCompanies,
        payload,
      })
    },
    [`${administrationCompanies}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.adminCompanies,
        payload,
      })
    },
    [`${administrationCompanies}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminCompanies,
        payload,
      })
    },
    [`${administrationCompanies}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminCompanies,
        payload,
      })
    },
    // ------ administration/elabels ------
    [`${administrationELabels}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.adminElabels,
        payload,
      })
    },
    [`${administrationELabels}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.adminElabels,
        payload,
      })
    },
    [`${administrationELabels}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminElabels,
        payload,
      })
    },
    [`${administrationELabels}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminElabels,
        payload,
      })
    },
    [`${administrationELabels}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminElabels,
        payload,
      })
    },
    [`${administrationELabels}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.adminElabels,
        payload,
      })
    },
    [`${administrationELabels}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminElabels,
        payload,
      })
    },
    [`${administrationELabels}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminElabels,
        payload,
      })
    },
    // ------ my-reviews ------
    [`${myReviews}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: MY_REVIEW_ROUTE_NAME,
        payload,
      })
    },
    [`${myReviews}Filters`]: (state, { payload }) => {
      setFilters({ state, table: MY_REVIEW_ROUTE_NAME, payload })
    },
    [`${myReviews}RemoveFilter`]: (state, { payload }) => {
      removeFilter({ state, table: MY_REVIEW_ROUTE_NAME, payload })
    },
    [`${myReviews}Filter`]: (state, { payload }) => {
      setFilter({ state, table: MY_REVIEW_ROUTE_NAME, payload })
    },

    [`${myReviews}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: MY_REVIEW_ROUTE_NAME,
        payload,
      })
    },
    [`${myReviews}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: MY_REVIEW_ROUTE_NAME,
        payload,
      })
    },
    [`${myReviews}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: MY_REVIEW_ROUTE_NAME,
        payload,
      })
    },
    [`${myReviews}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: MY_REVIEW_ROUTE_NAME,
        payload,
      })
    },
    [`${myReviews}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: MY_REVIEW_ROUTE_NAME,
        payload,
      })
    },
    // ------ admin/offers ------
    [`${adminOffers}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffers}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    [`${adminOffersByName}`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: ADMIN_ROUTE_NAMES.adminOffers,
        payload,
      })
    },
    // ------ database/producers ------
    [`${databaseProducers}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}PriceInterval`]: (state, { payload }) => {
      setPriceIntervalByKey({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    [`${databaseProducers}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducers,
        payload,
      })
    },
    // ------ database/producers/duplicates ------
    [`${databaseProducersDuplicates}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}LastUpdatedFilter`]: (
      state,
      { payload }
    ) => {
      setLastUpdatedFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    [`${databaseProducersDuplicates}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseProducersDuplicates,
        payload,
      })
    },
    // ------ database/brands ------
    [`${databaseBrands}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: DATABASE_ROUTE_NAMES.databaseBrands,
        payload,
      })
    },
    [`${databaseBrands}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: DATABASE_ROUTE_NAMES.databaseBrands,
        payload,
      })
    },
    [`${databaseBrands}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseBrands,
        payload,
      })
    },
    [`${databaseBrands}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseBrands,
        payload,
      })
    },
    [`${databaseBrands}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseBrands,
        payload,
      })
    },
    [`${databaseBrands}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: DATABASE_ROUTE_NAMES.databaseBrands,
        payload,
      })
    },
    [`${databaseBrands}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseBrands,
        payload,
      })
    },
    [`${databaseBrands}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseBrands,
        payload,
      })
    },
    // ------ database/exhibitions ------
    [`${databaseExhibitions}Participants`]: (state, { payload }) => {
      setParticipantsInterval({
        state,
        table: DATABASE_ROUTE_NAMES.databaseExhibitions,
        payload,
      })
    },
    [`${databaseExhibitions}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: DATABASE_ROUTE_NAMES.databaseExhibitions,
        payload,
      })
    },
    [`${databaseExhibitions}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: DATABASE_ROUTE_NAMES.databaseExhibitions,
        payload,
      })
    },
    [`${databaseExhibitions}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseExhibitions,
        payload,
      })
    },
    [`${databaseExhibitions}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseExhibitions,
        payload,
      })
    },
    [`${databaseExhibitions}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseExhibitions,
        payload,
      })
    },
    [`${databaseExhibitions}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: DATABASE_ROUTE_NAMES.databaseExhibitions,
        payload,
      })
    },
    [`${databaseExhibitions}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseExhibitions,
        payload,
      })
    },
    [`${databaseExhibitions}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseExhibitions,
        payload,
      })
    },
    // ------ database/wines ------
    [`${databaseWines}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}PriceInterval`]: (state, { payload }) => {
      setPriceIntervalByKey({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    [`${databaseWines}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: DATABASE_ROUTE_NAMES.databaseWines,
        payload,
      })
    },
    // ------ my-wines/e-labels ------
    [`${eLabels}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: E_LABELS_ROUTE_NAME,
        payload,
      })
    },
    [`${eLabels}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: E_LABELS_ROUTE_NAME,
        payload,
      })
    },
    [`${eLabels}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: E_LABELS_ROUTE_NAME,
        payload,
      })
    },
    [`${eLabels}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: E_LABELS_ROUTE_NAME,
        payload,
      })
    },
    [`${eLabels}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: E_LABELS_ROUTE_NAME,
        payload,
      })
    },
    [`${eLabels}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: E_LABELS_ROUTE_NAME,
        payload,
      })
    },
    [`${eLabels}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: E_LABELS_ROUTE_NAME,
        payload,
      })
    },
    [`${eLabels}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: E_LABELS_ROUTE_NAME,
        payload,
      })
    },
    [`${eLabels}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: E_LABELS_ROUTE_NAME,
        payload,
      })
    },
    // ------ my-wines/wines ------
    [`${myWines}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },
    [`${myWines}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },
    [`${myWines}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },
    [`${myWines}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },
    [`${myWines}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },
    [`${myWines}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },
    [`${myWines}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },
    [`${myWines}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },
    [`${myWines}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },
    [`${myWines}ByName`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: MY_WINES_ROUTE_NAME,
        payload,
      })
    },

    // ------ database/geo-indication ------
    [`${databaseGeoIndication}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: DATABASE_ROUTE_NAMES.databaseGeoIndication,
        payload,
      })
    },
    [`${databaseGeoIndication}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: DATABASE_ROUTE_NAMES.databaseGeoIndication,
        payload,
      })
    },
    [`${databaseGeoIndication}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseGeoIndication,
        payload,
      })
    },
    [`${databaseGeoIndication}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseGeoIndication,
        payload,
      })
    },
    [`${databaseGeoIndication}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: DATABASE_ROUTE_NAMES.databaseGeoIndication,
        payload,
      })
    },
    [`${databaseGeoIndication}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: DATABASE_ROUTE_NAMES.databaseGeoIndication,
        payload,
      })
    },
    [`${databaseGeoIndication}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseGeoIndication,
        payload,
      })
    },
    [`${databaseGeoIndication}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: DATABASE_ROUTE_NAMES.databaseGeoIndication,
        payload,
      })
    },

    // ------ buy-wine/analysis
    [`${buyWineAnalysis}Rrp`]: (state, { payload }) => {
      setRrpInterval({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysisByName}`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    [`${buyWineAnalysis}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysis,
        payload,
      })
    },
    // ------ buy-wine/offers ------
    [`${buyWineOffers}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffersByName}`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    [`${buyWineOffers}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineOffers,
        payload,
      })
    },
    // ------ buy-wine/wines ------
    [`${buyWineWines}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWinesByName}`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    [`${buyWineWines}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineWines,
        payload,
      })
    },
    // ------ retail/e-shops ------
    [`${retailEShops}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShopsByName}`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    [`${retailEShops}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: RETAIL_ROUTE_NAMES.retailEShops,
        payload,
      })
    },
    // ------ retail/geo-indications ------
    [`${retailGeoIndications}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndicationsByName}`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    [`${retailGeoIndications}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: RETAIL_ROUTE_NAMES.retailGeoIndications,
        payload,
      })
    },
    // ------ retail/wines ------
    [`${retailWines}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWinesByName}`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    [`${retailWines}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: RETAIL_ROUTE_NAMES.retailWines,
        payload,
      })
    },
    // ------ retail/buyers-wine ------
    [`${retailBuyersWine}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWineByName}`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },
    [`${retailBuyersWine}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: RETAIL_ROUTE_NAMES.retailBuyersWine,
        payload,
      })
    },

    // ------ buy-wine/analysis/rating ------
    [`${buyWineAnalysisRating}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRating}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },
    [`${buyWineAnalysisRatingByName}`]: (state, { payload }) => {
      setFilterByName({
        state,
        table: OFFERS_ROUTE_NAMES.buyWineAnalysisRating,
        payload,
      })
    },

    // ------sell-wine/my-offers ------
    [`${sellWineMyOffers}FiltersApplied`]: (state, { payload }) => {
      setFiltersApplied({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}AlcoholLevels`]: (state, { payload }) => {
      setAlcoholLevels({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}CurrencyDefault`]: (state, { payload }) => {
      setCurrencyDefault({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}PriceInterval`]: (state, { payload }) => {
      setPriceInterval({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}Filters`]: (state, { payload }) => {
      setFilters({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}RemoveFilter`]: (state, { payload }) => {
      removeFilter({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}Filter`]: (state, { payload }) => {
      setFilter({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}LastUpdatedFilter`]: (state, { payload }) => {
      setLastUpdatedFilter({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}SetFilterErrors`]: (state, { payload }) => {
      setFilterErrors({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}Preset`]: (state, { payload }) => {
      setPreset({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    [`${sellWineMyOffers}AppliedPreset`]: (state, { payload }) => {
      setAppliedPreset({
        state,
        table: OFFERS_ROUTE_NAMES.sellWineMyOffers,
        payload,
      })
    },
    setPerPage: (state, { payload }) => {
      return {
        ...state,
        perPage: payload,
      }
    },
  },
})

export const { actions, reducer: tableFilters } = tableFiltersSlice
