import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LOGIN_PAGE_PATH, SIGNUP_PAGE_PATH } from 'utils/consts/path'

const Wrapper = styled.div`
  display: flex;
  height: 64px;
  background: var(--color-white);
  border-radius: 16px 16px 0 0;
  width: 100%;
`
const Login = styled.div`
  width: 50%;
  box-sizing: border-box;
  height: calc(100% - 4px);
  border-bottom: 4px solid var(--color-brand-red);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-brand-red);
  font: var(--typography-14-16-semibold);
  cursor: pointer;
`
const Signup = styled(Login)`
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-sw-10);
  color: #717284;

  :hover {
    color: var(--color-night);
  }
`

const Header = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Login onClick={() => history.replace(LOGIN_PAGE_PATH)}>
        {t('login.login')}
      </Login>
      <Signup onClick={() => history.replace(SIGNUP_PAGE_PATH)}>
        {t('login.signup')}
      </Signup>
    </Wrapper>
  )
}

export default Header
