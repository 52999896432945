import styled from 'styled-components'
import { ButtonContent } from 'components/Common/MasterComponents'
import PrimaryButton from 'components/Common/MasterComponents/Buttons/PrimaryButton'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import useNotification from 'utils/notification'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`
const Button = styled(PrimaryButton)`
  height: 48px;
  padding: 16px;
  width: fit-content;
  border-radius: 8px;
  cursor: ${props => (props.fakeDisabled ? 'auto' : 'pointer')};
  font: var(--typography-14-16-semibold);
  transition: background-color 0.2s;
`

const TIMER_SECONDS = 60
const SendAgainButton = ({ onSendAgain, loading }) => {
  const notify = useNotification()
  const [seconds, setSeconds] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const { t } = useTranslation()
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        clearInterval(myInterval)
        setDisabled(false)
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  }, [seconds])

  const handleSend = async () => {
    if (disabled) return
    try {
      await onSendAgain()
    } catch (e: any) {
      notify(e.message, 'error')
    }
    setSeconds(TIMER_SECONDS)
    setDisabled(true)
    onSendAgain()
  }
  return (
    <Wrapper>
      <Button fakeDisabled={disabled} onClick={handleSend}>
        <ButtonContent loading={loading}>{`${t('signup.sendAgain')} ${
          seconds > 0 ? `(${seconds})` : ''
        }`}</ButtonContent>
      </Button>
    </Wrapper>
  )
}

export default SendAgainButton
