import Dialog from 'components/Common/Modals/Dialog'
import styled from 'styled-components'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents/Buttons/PrimaryButtonFlexable48'
import { useTranslation } from 'react-i18next'
import DeclinedIcon from './DeclinedIcon'

const Main = styled.div`
  width: 100%;
  max-width: 328px;
`
const Footer = styled.div`
  display: flex;
  width: 100%;
`
const IconWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`
const Description = styled.div`
  color: var(--color-sns-80);
  text-align: center;
  font: var(--typography-14-20-regular);
  margin-bottom: 24px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Header = styled.div`
  color: var(--color-night-sky);
  font: var(--typography-18-24-semibold);
  margin-bottom: 4px;
`
const OkButton = styled(PrimaryButtonFlexable48)`
  width: 100%;
`

const BannedModal = ({ closeModal }) => {
  const { t } = useTranslation()
  return (
    <Dialog.Wrapper>
      <Main>
        <Dialog.Header />
        <Dialog.Content padding='24px'>
          <Content>
            <IconWrapper>
              <DeclinedIcon />
            </IconWrapper>
            <Header>{t('login.accessDenied.header')}</Header>
            <Description>{t('login.accessDenied.desc')}</Description>
            <Footer>
              <OkButton onClick={closeModal}>{t('common.ok')}</OkButton>
            </Footer>
          </Content>
        </Dialog.Content>
      </Main>
    </Dialog.Wrapper>
  )
}

export default BannedModal
