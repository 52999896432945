import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { emailMask } from 'utils/masks'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setTokens } from 'utils/token'
import Email from './Email'
import Forgot from './Forgot'
import Button from './Button'
import Password from './Password'
import { initLocalStorage } from 'components/Common/Menu/_methods'
import { getLocaleId } from 'utils/getLocaleId'
import ErrorHandler from './ErrorHandler'
import i18n from 'utils/i18n'
import { useTranslation } from 'react-i18next'
import { getError } from 'utils/apollo/getError'
import { setData, setSelectedCompanyId } from 'store/me'
import { HOMEPAGE_PATH } from 'utils/consts/path'
import NeedVerifyEmail from './NeedVerifyEmail'
import { captureException } from 'utils/sentry'
import {
  useGetMeLazyQuery,
  useSendConfirmEmailEmailMutation,
  useSignInMutation,
} from 'api/hooks'
import BannedModal from './BannedModal'

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`
const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ERROR_CODE_USER_NOT_FOUND = 'USER_NOT_FOUND'
const ERROR_CODE_UNAUTHENTICATED = 'UNAUTHENTICATED'
const ERROR_CODE_CONFIRM_EMAIL = 'EMAIL_CONFIRM_ERROR'

const Main: FC = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string>('')
  const [showVerifyEmail, setShowVerifyEmail] = useState(false)
  const location = useLocation<any>()
  const [banModalVisible, setBanModalVisible] = useState(false)

  const [password, setPassword] = useState('')
  const [inputType, setInputType] = useState('password')

  const [confirmEmail, { loading: sendConfirmLoading }] =
    useSendConfirmEmailEmailMutation()

  const [buttonDisabled, setButtonDisabled] = useState(true)

  const [getMe] = useGetMeLazyQuery({
    variables: { localeId: getLocaleId() },
    context: { ignoreAccessErrors: true },
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (!data?.me) return
      const pathname = location?.state?.from?.pathname
      const redirectPath =
        pathname === '/' ? HOMEPAGE_PATH : pathname || HOMEPAGE_PATH
      const { selectedCompany } = initLocalStorage(data?.me)

      dispatch(setData(data.me))
      dispatch(setSelectedCompanyId(selectedCompany?.id))

      history.replace(redirectPath)
    },
  })
  const dispatch = useDispatch()

  useEffect(() => {
    const v = validation()
    setButtonDisabled(!v)
  }, [email, password])

  useEffect(() => {
    if (location.search.includes('access_denied=true')) {
      setBanModalVisible(prevState => !prevState)
    }
  }, [location])

  const [logIn, { data, loading, error }] = useSignInMutation()
  const history = useHistory()

  const validation = () => {
    return email && emailMask.test(email) && password
  }

  const handleEmailChange = e => {
    const { value } = e.target
    setEmail(value)
    setEmailError('')
  }

  const handleEmailBlur = () => {
    setEmailError(email && emailMask.test(email) ? '' : 'login.error.email')
  }

  const handlePasswordChange = e => {
    const { value } = e.target
    setPassword(value)
    setPasswordError('')
  }

  const toggleVisibilityPassword = () => {
    inputType === 'password' ? setInputType('text') : setInputType('password')
  }

  const handleRequest = async () => {
    const v = validation()
    if (v && !data) {
      try {
        setEmailError('')
        setPasswordError('')
        const localeId = getLocaleId()
        const res = await logIn({
          variables: {
            email: email.trim(),
            password: password.trim(),
            localeId,
          },
        })
        const token = res?.data?.signIn?.token
        const refreshToken = res?.data?.signIn?.refreshToken
        const locale = res?.data?.signIn?.locale?.locale
        if (token && refreshToken) {
          setTokens(token, refreshToken)
          localStorage.setItem('wwl-lng', locale || '')
          i18n.changeLanguage(locale).then(() => {
            getMe()
          })
        }
      } catch (e) {
        captureException(e)
      }
    } else {
      setEmailError(email && emailMask.test(email) ? '' : 'login.error.email')
    }
  }

  const errorCode = getError(error, 'code')
  const showErrorTooltip =
    errorCode &&
    ![ERROR_CODE_UNAUTHENTICATED, ERROR_CODE_USER_NOT_FOUND].includes(errorCode)

  useEffect(() => {
    if (errorCode === ERROR_CODE_CONFIRM_EMAIL) {
      setShowVerifyEmail(true)
    }
    if (errorCode === ERROR_CODE_USER_NOT_FOUND) {
      setEmailError('login.error.accountNotFound')
    }
    if (errorCode === ERROR_CODE_UNAUTHENTICATED) {
      setPasswordError('login.error.passwordWrong')
    }
  }, [t, errorCode])

  const handleConfirmEmail = () => {
    return confirmEmail({
      variables: { email },
    })
  }

  return (
    <Wrapper>
      {showVerifyEmail ? (
        <NeedVerifyEmail
          loading={sendConfirmLoading}
          onSendAgain={handleConfirmEmail}
        />
      ) : (
        <>
          <Block>
            <Email
              handleChange={handleEmailChange}
              handleBlur={handleEmailBlur}
              value={email}
              error={t(emailError)}
            />
            <Password
              handleChange={handlePasswordChange}
              value={password}
              type={inputType}
              toggle={toggleVisibilityPassword}
              handleRequest={handleRequest}
              error={t(passwordError)}
            />
          </Block>
          <Button
            handleRequest={handleRequest}
            disabled={buttonDisabled}
            loading={loading}
            error={error}
            data={data}
          />
          <Forgot />
        </>
      )}
      {showErrorTooltip && <ErrorHandler error={error} />}
      {banModalVisible && (
        <BannedModal closeModal={() => setBanModalVisible(false)} />
      )}
    </Wrapper>
  )
}

export default Main
